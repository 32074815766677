const Config = {
    defaultPath: '/',
    defaultNotAuthenticatePath: '/regiao/sp/sorocaba/eden',
    basename: '',
    layout: 'horizontal',
    subLayout: 'horizontal-2',
    collapseMenu: false,
    layoutType: 'menu-light',
    headerBackColor: 'bg-orange',
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: false,
    boxLayout: false
};
export default Config;
