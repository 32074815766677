import { isAuthenticated } from "./auth";

// function RequireAuth({ children }) {
//     return isAuthenticated ? children : <Navigate to="/auth/signin" replace />;
//   }
export const RequireAuth = () => isAuthenticated();
//{
    // if(isAuthenticated)
    //   <Navigate to="/auth/signin" replace />;

  //    return isAuthenticated;
//  }