import * as React from 'react';
// const HomeDefault = React.lazy(() => import('./Demo/Home/Default'));
const DashboardDefault = React.lazy(() => import('./Demo/Dashboard/Default'));
const CompanyDefault = React.lazy(() => import('./Demo/Company/Default'));
// const CustomerDefault = React.lazy(() => import('./Demo/Customer/Default'));
// const ProductDefault = React.lazy(() => import('./Demo/Product/Default'));
// const RequestDefault = React.lazy(() => import('./Demo/Request/Default'));
// const ServiceDefault = React.lazy(() => import('./Demo/Service/Default'));
const PaymentDefault = React.lazy(() => import('./Demo/Payment/Checkout/Default'));
const PromotionDefault = React.lazy(() => import('./Demo/Promotion/Default'));
// const ContractDefault = React.lazy(() => import('./Demo/Contract/Default'));
// const RepresentationDefault = React.lazy(() => import('./Demo/Representation/Default'));
// const CompetitorsDefault = React.lazy(() => import('./Demo/Competitors/Default'));
// const ReportDefault = React.lazy(() => import('./Demo/Report/Default'));
// const RobotsDefault = React.lazy(() => import('./Demo/Robots/Default'));
const LastMinuteDefault = React.lazy(() => import('./Demo/LastMinute/Default'));
const LastMinuteProfileDefault = React.lazy(() => import('./Demo/LastMinute/Profile'));
const LastMinuteDetailsDefault = React.lazy(() => import('./Demo/LastMinute/Details'));
const UserProfile = React.lazy(() => import('./Demo/Authentication/Profile/Default'));
// const DeviceDefault = React.lazy(() => import('./Demo/Devices/New'));


const routes = [ 
    // { path: '/home',  name: 'Home', component: HomeDefault },
    { path: '/dashboard',  name: 'Dashboard', component: DashboardDefault },
    { path: '/empresa',  name: 'Empresas', component: CompanyDefault },
    // { path: '/cliente',  name: 'Clientes', component: CustomerDefault },
    // { path: '/contrato',  name: 'Contratos', component: ContractDefault },
    // { path: '/produto',  name: 'Produtos', component: ProductDefault },
    // { path: '/pedido',  name: 'Pedidos', component: RequestDefault },
    // { path: '/servico',  name: 'Servicos', component: ServiceDefault },
    { path: '/pagamento',  name: 'Receitas', component: PaymentDefault },
    // { path: '/representacao',  name: 'Representacoes', component: RepresentationDefault },
    // { path: '/relatorios',  name: 'Relatorios', component: ReportDefault },
    { path: '/promocao',  name: 'Promocao', component: PromotionDefault },
    // { path: '/concorrentes',  name: 'Concorrentes', component: CompetitorsDefault },
    // { path: '/robos',  name: 'Robos', component: RobotsDefault },
    // { path: '/ultimominuto/:estado?/:cidade?/:bairro?/:grupo?',  name: 'Ultimo Minuto', component: LastMinuteDefault },
    // { path: '/ultimominuto/:nome',  name: 'Ultimo Minuto', component: LastMinuteProfileDefault },
    // { path: '/ultimominutodetalhes/:externalid',  name: 'Ultimo Minuto', component: LastMinuteDetailsDefault },
    { path: '/userprofile', name: 'UserProfile', component: UserProfile },
    // { path: '/novodispositivo', exact: true, name: 'Dispositivos', component: DeviceDefault },
];
export default routes;
