import * as React from 'react';

// const StagePage = React.lazy(() => import('./Demo/Stage/Default'));
// const DivulgaPage = React.lazy(() => import('./Demo/Divulga/Default'));
// const PersonPage = React.lazy(() => import('./Demo/Person/Default'));
// const SearchPage = React.lazy(() => import('./Demo/Search/Default'));
const SignUp = React.lazy(() => import('./Demo/Authentication/SignUp/Default'));
const Signin = React.lazy(() => import('./Demo/Authentication/SignIn/Default'));
// const Profile = React.lazy(() => import('./Demo/Authentication/Profile/UserProfile'));
const ChangePassword = React.lazy(() => import('./Demo/Authentication/ChangePassword'));
const ResetPassword = React.lazy(() => import('./Demo/Authentication/ResetPassword/ResetPassword'));
const ResetPasswordForm = React.lazy(() => import('./Demo/Authentication/ResetPassword/ResetPasswordForm'));
const ResetPasswordSuccess = React.lazy(() => import('./Demo/Authentication/ResetPassword/ResetPasswordSuccess'));
const ResetPasswordConfirm = React.lazy(() => import('./Demo/Authentication/ResetPassword/ResetPasswordConfirm'));
// const Checkout = React.lazy(() => import('./Demo/Pagamento/Checkout/Checkout'));
const LastMinuteDefault = React.lazy(() => import('./Demo/LastMinute/Default'));
const LastMinuteProfileDefault = React.lazy(() => import('./Demo/LastMinute/Profile'));
// const DevicesDefault = React.lazy(() => import('./Demo/Devices/Default'));
const EventsDefault = React.lazy(() => import('./Demo/Events/Default'));
// const QuotationDefault = React.lazy(() => import('./Demo/Quotation/Default'));
const StreamingDefault = React.lazy(() => import('./Demo/Stream/Default'));
const LastMinuteDetailsDefault = React.lazy(() => import('./Demo/LastMinute/Details'));
const EasterEggDefault = React.lazy(() => import('./Demo/EasterEgg/Default'));
//TODO: refactor
// const userIsAuth = sessionStorage.getItem('token') !== null && sessionStorage.getItem("user") !== null

const route = [
    // { path: '/portal', exact: true, name: 'Portal', component: StagePage },
    // { path: '/portal/busca', exact: true, name: 'Busca', component: SearchPage },
    // { path: '/portal/rank/bullshit', exact: true, name: 'Rank', component: StagePage },
    // { path: '/portal/social/info', exact: true, name: 'Social Info', component: StagePage },
    // { path: '/portal/info', exact: true, name: 'Info', component: InfoCompanyPage },
    // { path: '/portal/empresa', exact: true, name: 'Empresa', component: InfoCompanyPage },
    { path: '/eventos/:externalid', exact: true, name: 'Eventos', component: EventsDefault },
    { path: '/easteregg', exact: true, name: 'Easter Egg', component: EasterEggDefault },
    // { path: '/cotacao/:externalid', exact: true, name: 'Cotação', component: QuotationDefault },
    { path: '/transmissao/:externalid', exact: true, name: 'Transmissão', component: StreamingDefault },
    // { path: '/dispositivos/:externalid', exact: true, name: 'Dispositivos', component: DevicesDefault },
    { path: '/regiao/:estadoparam?/:cidadeparam?/:bairroparam?/:grupoparam?',  exact: true, name: 'Ultimo Minuto', component: LastMinuteDefault },
    { path: '/detalhes/:externalid', exact: true, name: 'Ultimo Minuto', component: LastMinuteDetailsDefault },
    // { path: '/profile/:externalid',  name: 'Ultimo Minuto', component: LastMinuteProfileDefault },
    // { path: '/portal/pessoa', exact: true, name: 'Pessoas', component: PersonPage },
    { path: '/auth/signup', exact: true, name: 'Signup', component: SignUp },
    { path: '/auth/signin', exact: true, name: 'Signin', component: Signin },
    // { path: '/profile/:nick', exact: true, name: 'Profile', component: Profile },
    { path: '/auth/reset-password',  exact: true, name: 'Reset Password', component: ResetPassword},
    { path: '/auth/reset-password-form',  exact: true, name: 'Reset Password Form', component: ResetPasswordForm},
    { path: '/auth/reset-password-success',  exact: true, name: 'Reset Password Form', component: ResetPasswordSuccess},
    { path: '/auth/reset-password-confirm',  exact: true, name: 'Reset Password Form', component: ResetPasswordConfirm},
    { path: '/auth/change-password', exact: true, name: 'Change Password', component: ChangePassword },
    // { path: '/payment/checkout', exact: true, name: 'Checkout', component: Checkout }
];
export default route;
