import React, { useState, useRef, useEffect, componentDidMount } from 'react';
import { lazy, Suspense } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Loader from './layout/Loader';
import ScrollToTop from './layout/ScrollToTop';
import routesOnePage from '../route';
import routes from '../routes';
import Config from '../config';
import { RequireAuth } from '../Services/auth.validade';
import { isAuthenticated } from "../Services/auth";
import { NavLink, useHistory } from 'react-router-dom';
import '../App.css';

const AdminLayout = lazy(() => import('./layout/AdminLayout'));

const App = () => {
    let history = useHistory();
    const location = useLocation();
    let checkAuth = RequireAuth();
    const menuItems = JSON.parse(sessionStorage.getItem("user"))?.menu;
    // const onboading = JSON.parse(sessionStorage.getItem("user"))?.step;
    let menuAuthenticated = menuItems?.filter(x => x.isPublic === false && x.isActive === true);
    sessionStorage.setItem("menuAuthenticated", menuAuthenticated);
    let menuNotAuthenticated = menuItems?.filter(x => x.isPublic === true && x.isActive === true);
    let redirectRoute = checkAuth && menuAuthenticated.filter(item => location.pathname.includes(item.menuRoute)).length > 0;
    // let redirectRoute = (onboading == 0 || onboading == null) && checkAuth && menuAuthenticated.filter(item => location.pathname.includes(item.menuRoute)).length > 0;

    return redirectRoute ? (<>
    <ScrollToTop>
        <Suspense fallback={<Loader />}>
            <Route path={menuAuthenticated.map((x) => x.menuRoute)}>
                <Switch location={location} key={location.pathname}>
                    {menuAuthenticated.map((route, index) => {
                        return route.component ? (<Route key={index} path={route.menuRoute} exact={route.isRouteExact} render={(props) => <route.component {...props} />} />) : null;
                    })}
                </Switch>
            </Route>
            <Route path={menuAuthenticated.map((x) => x.menuRoute)}>
                <AdminLayout />
            </Route>
            <Route path={'/'} exact>
                <Redirect to={Config.defaultPath} />
            </Route>
        </Suspense>
    </ScrollToTop>
    <div className="backdrop" />
</>) : (
    <>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
            <Route path={routesOnePage.map((x) => x.path)}>
                <Switch location={location} key={location.pathname}>
                    {routesOnePage.map((route, index) => {
                        return route.component ? (<Route key={index} path={route.path} exact={route.exact} render={(props) => <route.component {...props} />} />) : null;
                    })}
                </Switch>
            </Route>
            <Route path={'/'} exact>
                    <Redirect to={Config.defaultNotAuthenticatePath}/>
                </Route>
        </Suspense>
    </ScrollToTop> 
    </>);
};
export default App;
