export const TOKEN_KEY = "token";
export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null && sessionStorage.getItem("user") !== null;
export const getToken = () => sessionStorage.getItem(TOKEN_KEY);
export const authenticate = token => {
  sessionStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.clear();
  window.location.href="/"
};